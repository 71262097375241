import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { selectOnboarding } from 'store/selectors';

import { Box } from 'wui/Box';
import { CardContent, type ICardContentProps } from 'wui/CardContent';

import { OnboardingStep } from '../OnboardingStep';
import { OnboardingProgress } from './OnboardingProgress';

export type StateHandlerProps = {
  groupId: string;
} & ICardContentProps;

export function OnboardingSteps(props: StateHandlerProps) {
  const { groupId, ...rest } = props;

  const onboarding = useSelector(selectOnboarding);

  return (
    <CardContent padding={0} {...rest}>
      <OnboardingProgress steps={onboarding.data.steps} />

      <Box gap="0" direction="vertical">
        {onboarding.data.steps.map((step) => (
          <OnboardingStep
            data-hook="onboarding-step"
            groupId={props.groupId}
            key={step.stepKey}
            step={step}
          />
        ))}
      </Box>
    </CardContent>
  );
}

OnboardingSteps.displayName = 'OnboardingSteps';
