import React from 'react';

// UI
import { ListItem, type IListItemProps } from 'wui/ListItem';
import { ListItemButton } from 'wui/ListItemButton';
import { ListItemIcon } from 'wui/ListItemIcon';
import { ListItemText } from 'wui/ListItemText';
import { Typography } from 'wui/Typography';
import { SelectFill as SelectFillIcon } from '@wix/wix-ui-icons-common/on-stage';

// types
import { Status } from '@wix/ambassador-groups-onboarding-v1-onboarding/types';

import classes from './Step.scss';

export type IStepProps = {
  disabled?: boolean;
  icon: React.JSX.Element;
  title: string;
  status?: Status;
  onClick: () => void;
} & IListItemProps;

export const Step = ({
  onClick,
  disabled,
  icon,
  title,
  status,
  ...rest
}: IStepProps) => {
  const isCompleted = status === 'COMPLETED';
  const completedClassName = isCompleted ? classes.completed : undefined;

  return (
    <ListItem
      data-step-status={status}
      data-step-item="onboarding-step"
      className={classes.root}
      {...rest}
    >
      <ListItemButton
        data-hook="cta"
        onClick={onClick}
        disabled={disabled}
        className={classes.cta}
      >
        <ListItemIcon data-hook="step-icon" className={completedClassName}>
          {isCompleted ? <SelectFillIcon /> : icon}
        </ListItemIcon>
        <ListItemText
          data-hook="title"
          title={
            <Typography className={completedClassName}>{title}</Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

Step.displayName = 'Step';
